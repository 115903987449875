import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useAuth from "../../../../hooks/useAuth";
// import useSnack from "../../../../hooks/useSnack";
import { ItemMetaState } from "../../../../store/states";

interface LeftMenuProps {
  open: boolean;
  onClose: () => void;
}
const LeftMenu: React.FC<LeftMenuProps> = props => {
  const navi = useNavigate();
  // const { openSnack } = useSnack();
  const { auth, logout } = useAuth();
  const itemMetaState = useRecoilValue(ItemMetaState);

  // depreac
  // const [showPanel, setShowPanel] = useState<"shop" | "intro">("shop");

  const [openShop, setOpenShop] = useState<boolean>(true);
  // const [openService, setOpenService] = useState<boolean>(false);
  // const [openFnb, setOpenFnb] = useState<boolean>(false);

  const handleLogout = () => {
    logout();
    props.onClose();
  };

  const open = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    navi(path);
    props.onClose();
  };

  return (
    <div id="ly_menu" className="a-layer-menu new-type" style={{ display: props.open ? undefined : "none" }}>
      <div className="a-layer-menu__box">
        <div className="a-layer-menu__gnb">
          <a href="#" className="btn_userinfo" onClick={auth.id ? open("/mypage") : open("/login")}>
            {auth.id ? auth.name + " 님" : "로그인해주세요."}
          </a>
          <a href="#" className="btn_reservation" onClick={open("/reservation")}>
            쇼룸 예약하기
          </a>
        </div>
        <div className="a-layer-menu__scroll">
          <a href="#" className="a-layer-menu__subtitle" onClick={open("/intro")}>
            앤더슨씨 소개
          </a>
          <div className="a-layer-menu__cont" style={{ display: openShop ? "block" : "none" }}>
            <ul>
              <li>
                <a href="#" onClick={open("/intro/gallery")}>
                  Gallery
                </a>
              </li>
              <li>
                <a href="#" onClick={open("/intro/fnb")}>
                  F&B
                </a>
              </li>
            </ul>
          </div>

          <div className="a-layer-menu__wrap">
            <div className="a-layer-menu__button">
              <a href="#" className="a-layer-menu__link" onClick={open("/list")}>
                온라인샵
              </a>
              <button
                type="button"
                className={classNames("a-layer-menu__toggle", { selected: openShop })}
                onClick={() => setOpenShop(b => !b)}>
                <span className="blind">펼치기/접기</span>
              </button>
            </div>
            <div className="a-layer-menu__cont" style={{ display: openShop ? "block" : "none" }}>
              <ul>
                <li>
                  <a href="#" onClick={open("/list")}>
                    전체보기
                  </a>
                </li>
                {itemMetaState.topCategory.map((category, idx, all) => {
                  return (
                    <li key={"topCategory_" + category.id}>
                      <a href="#" onClick={open("/list?categoryId=" + category.id)}>
                        {category.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {/* <div className="a-layer-menu__wrap view_pc">
            <div className="a-layer-menu__button">
              <a href="#" className="a-layer-menu__link" onClick={open("/intro/gellery")}>
                서비스 신청
              </a>
              <button
                type="button"
                className={classNames("a-layer-menu__toggle", { selected: openService })}
                onClick={() => setOpenService(b => !b)}>
                <span className="blind">펼치기/접기</span>
              </button>
            </div>
            <div className="a-layer-menu__cont" style={{ display: openService ? "block" : "none" }}>
              <ul>
                <li>
                  <a href="#" onClick={open("styling")}>
                    공간 스타일링
                  </a>
                </li>
                <li>
                  <a href="#" onClick={open("consignment")}>
                    위탁/매입 서비스
                  </a>
                </li>
                <li>
                  <a href="#" onClick={open("rental")}>
                    렌탈 서비스
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="a-layer-menu__wrap view_pc">
            <div className="a-layer-menu__button">
              <a href="#" className="a-layer-menu__link" onClick={open("/intro/fnb")}>
                <strong>F&amp;B</strong>
              </a>
              <button
                type="button"
                className={classNames("a-layer-menu__toggle", { selected: openFnb })}
                onClick={() => setOpenFnb(b => !b)}>
                <span className="blind">펼치기/접기</span>
              </button>
            </div>
            <div className="a-layer-menu__cont" style={{ display: openFnb ? "block" : "none" }}>
              <ul>
                <li>
                  <a href="#" onClick={open("fnb_sungsu")}>
                    앤더슨씨 청담점
                  </a>
                </li>
                <li>
                  <a href="#" onClick={open("fnb_hannam")}>
                    앤더슨씨 나인원
                  </a>
                </li>
                <li>
                  <a href="#" onClick={open("fnb_and_haven")}>
                    앤더슨씨 삼성점 (And Haven)
                  </a>
                </li>
                <li>
                  <a href="#" onClick={open("fnb_quad")}>
                    앤더슨씨 도산점 (And 85)
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <ul className="a-layer-menu__etc">
            <li>
              <a href="mailto:andersoncgallery@gmail.com">andersoncgallery@gmail.com</a>
            </li>
            <li>
              <a href="#" className="btn_state" onClick={handleLogout}>
                로그아웃
              </a>
            </li>
          </ul>
        </div>
        <button type="button" className="a-layer-menu__close" onClick={() => props.onClose()}>
          <span className="blind">닫기</span>
        </button>
      </div>
    </div>
  );
};

export default LeftMenu;
